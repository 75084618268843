import { useEffect, useState } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { useAuthContext } from '../contexts/AuthProvider'
import { fetchPreferences } from '../../api/app/preferences'

const usePreferences = () => {
	const reduxPreferences = useSelector((s) => s.auth.preferences, shallowEqual)
	const dispatch = useDispatch()
	const { userId } = useAuthContext()
	/** Set on mount */
	useEffect(() => {
		/** Retrieve all from backend */
		if (!reduxPreferences) {
			dispatch(fetchPreferences(userId))
		}
	}, [])
	useEffect(() => {
		/** Retrieve all from backend */
		if (!reduxPreferences) {
			dispatch(fetchPreferences(userId))
		}
	}, [userId])
	return reduxPreferences
}

export default usePreferences
